<template>
	<v-container class="board pb-16">
		<v-row>
			<v-col cols="12" class="mt-3 mb-3">
        <h2 class="text-h4">{{ isModeNew ? 'Додати' : 'Редагувати' }} білборди</h2>
      </v-col>
      <v-col cols="12">
        <v-form @submit.prevent="submitBoard">
          <v-row no-gutters>
            <v-col cols="12" class="mb-5">
              <v-select v-model="board.place_id" :items="places" label="Населений пункт" outlined hide-details="auto"
                        @input="$v.board.place_id.$touch()" @blur="$v.board.place_id.$touch()"
                        :error-messages="placeErrors" class="is-required" />
            </v-col>
            <v-col cols="12" class="mb-5">
              <v-select v-model="board.operator_id" :items="owners" label="Оператор" outlined hide-details="auto"
                        @input="$v.board.operator_id.$touch()" @blur="$v.board.operator_id.$touch()"
                        :error-messages="operatorErrors" class="is-required" />
            </v-col>
            <v-col cols="6" class="mb-5">
              <v-text-field v-model="board.lat" label="Широта (lat)" outlined hide-details="auto"
                            @input="$v.board.lat.$touch()" @blur="$v.board.lat.$touch()"
                            :error-messages="latErrors" class="is-required mr-1" />
            </v-col>
            <v-col cols="6" class="mb-5">
              <v-text-field v-model="board.lng" label="Довгота (lng)" outlined hide-details="auto"
                            @input="$v.board.lng.$touch()" @blur="$v.board.lng.$touch()"
                            :error-messages="lngErrors" class="is-required ml-1" />
            </v-col>
            <v-col cols="12" class="mb-5">
              <v-select v-model="board.status" :items="statuses" label="Статус" outlined hide-details="auto"
                        @input="$v.board.status.$touch()" @blur="$v.board.status.$touch()"
                        :error-messages="statusErrors" class="is-required" />
            </v-col>
            <!-- <v-col>
              {{ imagesErrors }}
              {{ $v.board.images.$each.$iter }}
            </v-col> -->
            <v-col cols="12" class="mb-2" v-for="(image, imageIndex) in $v.board.images.$each.$iter" :key="image.$model.random">
              <v-row no-gutters>
                <v-col class="board_imagePreview" v-if="image.$model.image">
                  <a :href="`/api${image.$model.image}`" target="_blank">
                    <v-img width="100%" height="100%" :src="`/api${image.$model.image}`" />
                  </a>
                </v-col>
                <v-col>
                  <v-text-field v-model="image.title.$model" label="Назва" outlined hide-details="auto"
                                @input="image.title.$touch" @blur="image.title.$touch"
                                :error-messages="imagesErrors[imageIndex].title" class="is-required mr-1" />
                </v-col>
                <v-col>
                  <v-file-input @change="bindImage($event, image)" label="Файл" outlined hide-details="auto"
                                prepend-inner-icon="mdi-image" prepend-icon="" class=" is-required ml-1" truncate-length="10"
                                @blur="image.file.$touch" :error-messages="imagesErrors[imageIndex].file" />
                </v-col>
                <v-col class="board_deleteImage">
                  <v-btn icon @click="deleteImage(imageIndex)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="12" class="mb-2" v-for="(image, imageIndex) in board.images" :key="imageIndex">
              <v-row no-gutters align="center">
                <v-col class="board_imagePreview" v-if="image.image">
                  <a :href="`/api${image.image}`" target="_blank">
                    <v-img width="100%" height="100%" :src="`/api${image.image}`" />
                  </a>
                </v-col>
                <v-col>
                  <v-text-field v-model="image.title" label="Название" outlined hide-details="auto" class="mr-1" />
                </v-col>
                <v-col>
                  <v-file-input @change="bindImage($event, image)" label="Файл" outlined hide-details="auto"
                                prepend-inner-icon="mdi-image" prepend-icon="" class="ml-1" truncate-length="10" />
                </v-col>
                <v-col class="board_deleteImage">
                  <v-btn icon @click="deleteImage(imageIndex)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col> -->
            <v-col cols="12" class="mb-5">
              <v-btn depressed @click="addImage">{{ this.board.images.length ? 'Ще' : 'Додати' }} зображення</v-btn>
            </v-col>
            <!-- <v-col cols="12" class="mb-10 mt-5">
              <v-switch v-model="board.isAvailable" label="Свободен?" hide-details="auto" class="mt-0 pt-sm-0" />
            </v-col> -->
            <v-col cols="12">
              <v-btn color="primary" type="submit" dark depressed x-large :loading="isBeingSaved">
                {{ isModeNew ? 'Додати' : 'Зберегти зміни' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <AppPageLoader :visible="isPageLoading" />
    <v-snackbar v-model="snack" :timeout="5000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="snack = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators';
import { GET_BOARD, ADD_BOARD, EDIT_BOARD, CLEAR_BOARD_STATE, } from '@/store/boards/types';
import { GET_PLACES } from '@/store/places/types';
import { GET_OWNERS } from '@/store/owners/types';

export default {
  data: () => ({
    isPageLoading: false,
    board: {
      place_id: '',
      operator_id: '',
      lat: '',
      lng: '',
      status: '',
      isAvailable: false,
      images: [],
    },
    statuses: [
			{
				text: 'Схвалено',
				value: 'approved',
			},
			{
				text: 'Не схвалено',
				value: 'rejected',
			},
			{
				text: 'Обробляється',
				value: 'processing',
			},
		],
    isBeingSaved: false,
    snack: false,
    snackColor: '',
    snackText: '',
  }),
  props: ['id'],
  methods: {
    async init() {
      this.clearBoardState();
      this.isPageLoading = true;

      const listPromises = [
        this.getPlaces(),
        this.getOwners(),
      ];

      if (this.id !== 'new') {
        listPromises.push(this.getBoard());
      }

      await Promise.all(listPromises);

      setTimeout(() => {
        this.isPageLoading = false;
      }, 500);
    },
    async clearBoardState() {
      this.$store.dispatch(CLEAR_BOARD_STATE);
    },
    async getPlaces() {
      await this.$store.dispatch(GET_PLACES);
		},
		async getOwners() {
      await this.$store.dispatch(GET_OWNERS);
		},
		async getBoard() {
			await this.$store.dispatch(GET_BOARD, this.id);

      this.board.place_id = this.boardData.place;
      this.board.operator_id = this.boardData.owner;
      this.board.lat = this.boardData.location.lat;
      this.board.lng = this.boardData.location.lng;
      this.board.status = this.boardData.status;
      this.board.isAvailable = this.boardData.isAvailable;
      this.board.images = this.boardData.images;
      this.board.images.forEach(item => {
        item.random = this.randomImageId();
      });
		},
    async submitBoard() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      this.isBeingSaved = true;

      const formData = new FormData();
      Object.keys(this.board).forEach(key => {
        if (key === 'images') {
          this.board[key].forEach((image, imageIndex) => {
            formData.append(`${key}[${imageIndex}][title]`, image.title);

            if (image.file) {
              formData.append(`${key}[${imageIndex}][file]`, image.file);
            }
            if (image.id) {
              formData.append(`${key}[${imageIndex}][id]`, image.id);
            }
          });
        } else {
          formData.append(key, this.board[key]);
        }
      });
      formData.set('operator_id', this.ownersData.find(owner => owner.name === this.board.operator_id).id);
      formData.set('place_id', this.placesData.find(place => place.name === this.board.place_id).id);

      if (this.isModeNew) {
        try {
          await this.$store.dispatch(ADD_BOARD, formData);
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = 'Борд успішно додано!';

          this.$v.$reset();
          this.board.place_id = '';
          this.board.operator_id = '';
          this.board.lat = '';
          this.board.lng = '';
          this.board.status = '';
          this.board.isAvailable = '';
          this.board.images = [];

        } catch (e) {
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = 'Упс, щось пішло не так!';
        }
      } else {
        try {
          formData.append('id', this.id);
          formData.append('_method', 'PUT');
          await this.$store.dispatch(EDIT_BOARD, {
            formData,
            id: this.id,
          });
          await this.getBoard();
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = 'Зміни успішно збережені!';
        } catch (e) {
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = 'Упс, щось пішло не так!';
        }
      }
      
      this.isBeingSaved = false;
      window.scrollTo(0, 0);
    },
    addImage() {
      const getLetterByNumber = (number) => {
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let name = '';
        let t = '';
        let num = number;
        while (num > 0) {
          t = (num - 1) % 26;
          name = alphabet[t] + name;
          num = (num - t) / 26 | 0;
        }
        return name;
      };
      const title = `Сторона ${getLetterByNumber(this.board.images.length + 1)}`;
      this.board.images.push({ 
        title,
        file: null,
        random: this.randomImageId(),
      });
    },
    deleteImage(imageIndex) {
      this.board.images.splice(imageIndex, 1);
    },
    bindImage($event, image) {
      image.file.$model = $event;
      image.file.$touch;
    },
    randomImageId() {
      return Date.now() + Math.random();
    },
  },
  computed: {
    placesData() {
      return this.$store.getters.places;
    },
		places() {
			return this.placesData.map(place => place.name);
		},
		ownersData() {
      return this.$store.getters.owners;
    },
		owners() {
			return this.ownersData.map(owner => owner.name);
		},
    boardData() {
			return this.$store.getters.board;
    },
    latErrors () {
      const errors = [];
      if (!this.$v.board.lat.$dirty) return errors;
      !this.$v.board.lat.required && errors.push('Широта обов\'язкова.');
      !this.$v.board.lat.decimal && errors.push('Тільки цілі та дробові числа.');
      return errors;
    },
    lngErrors () {
      const errors = [];
      if (!this.$v.board.lng.$dirty) return errors;
      !this.$v.board.lng.required && errors.push('Довгота обов\'язкова.');
      !this.$v.board.lng.decimal && errors.push('Тільки цілі та дробові числа.');
      return errors;
    },
    placeErrors () {
      const errors = [];
      if (!this.$v.board.place_id.$dirty) return errors;
      !this.$v.board.place_id.required && errors.push('Населений пункт обов\'язковий.');
      return errors;
    },
    operatorErrors () {
      const errors = [];
      if (!this.$v.board.operator_id.$dirty) return errors;
      !this.$v.board.operator_id.required && errors.push('Оператор обов\'язковий.');
      return errors;
    },
    statusErrors () {
      const errors = [];
      if (!this.$v.board.status.$dirty) return errors;
      !this.$v.board.status.required && errors.push('Статус обов\'язковий.');
      return errors;
    },
    imagesErrors () {
      const images = this.$v.board.images.$each.$iter;
      return Object.keys(images).map((imageKey) => {
        const image = images[imageKey];
        const errors = {
          title: [],
          file: [],
        };

        if (image.title.$dirty) {
          !image.title.required && errors.title.push('Назва обов\'язкова.');
        }

        if (image.file.$dirty) {
          !image.file.required && errors.file.push('Зображення обов\'язкове.');
        }

        return errors;
      });
    },
    isModeNew() {
      return this.id === 'new';
    }
  },
  created() {
    this.init();
	},
  validations: {
    board: {
      lat: { 
        required,
        decimal,
      },
      lng: { 
        required,
        decimal,
      },
      place_id: {
        required,
      },
      operator_id: {
        required,
      },
      status: {
        required,
      },
      images: {
        $each: {
          title: {
            required,
          },
          file: {
            required(value, image) {
              if (image.id) {
                return true;
              } else {
                return !!value;
              }
            },
          },
          $trackBy: 'random',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.board {
  max-width: 600px;
}
.board_deleteImage {
  width: 36px;
  flex: unset;
  flex-shrink: 0;
  height: 56px;
  display: flex;
  align-items: center;
}
.board_imagePreview {
  width: 100px;
  flex: unset;
  flex-shrink: 0;
  height: 56px;
  margin-right: 8px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.38);

  ::v-deep {
    & {}
    .v-responsive__sizer {
      display: none;
    }
  }
}
</style>
